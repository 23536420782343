// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-jsx": () => import("/opt/build/repo/src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-collection-jsx": () => import("/opt/build/repo/src/pages/collection.jsx" /* webpackChunkName: "component---src-pages-collection-jsx" */),
  "component---src-pages-find-us-jsx": () => import("/opt/build/repo/src/pages/find_us.jsx" /* webpackChunkName: "component---src-pages-find-us-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

